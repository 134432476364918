// Login.js

import React, { useState, useRef, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'
import Image from 'react-bootstrap/Image'
import { useNavigate } from 'react-router-dom'
import warningIcon from '../assets/warning.svg'


const Login = ({ username, setUsername, handleRmsMatch }) => {
	const [errorMessage, setErrorMessage] = useState('');
	const [domainsData, setDomainsData] = useState(null);
	const navigate = useNavigate()
	const formRef = useRef(null)
 	// check if the email value is sent in url query parameter
	useEffect(() => {
		// save params
		const params = new URLSearchParams(window.location.search);
		// Check if the URL contains the parameter 'email'
		const email = params.get('email');
		// If 'email' exists, update the input field
		if (email) {
			setUsername(email);
		}
	}, []); 

	// Update state when string value changes
	const handleUsernameChange = (event) => {
		setUsername(event.target.value.toLowerCase());
		// Reset error message on change
		setErrorMessage('');
	}

	// validate email format
	const validateEmail = (email) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};
	/**
		 * Fetch the domains configuration json file, save it as domainsData state
		 */
	async function fetchdomains() {
		try {
			let domainsfile = "domains.json";
			if (window.location.hostname === "localhost") domainsfile = "domainsTest.json";
			const dataresponse = await fetch(domainsfile, { cache: "no-cache" });
			const domainsdata = await dataresponse.json();
			setDomainsData(domainsdata);
		} catch (e) {
			console.error("fetch error", e);
		}
	}
	useEffect(() => fetchdomains(), []);

	const handleSubmit = async (event) => {
		event.preventDefault()

		if (!username) {
			setErrorMessage('Email is required.');
			return;
		} else if (!validateEmail(username)) {
			setErrorMessage('Please enter a valid email address.');
			return;
		}

		// Extract domain from email
		const emailDomain = username.substring(username.lastIndexOf('@') + 1)

		try {
			// Find matching rms for domain
			const rmsMatch = domainsData.find(item => item.domain.toLowerCase() === emailDomain).rms

			if (rmsMatch) {
				if (rmsMatch.length === 1) {
					// If only one RMS is found, open the corresponding URL in a new tab
					window.location.href = `https://${rmsMatch[0]}/Ava8Web/login.htm?username=${encodeURIComponent(username)}`
					console.log(rmsMatch, 'one match found')
				} else if (rmsMatch.length >= 2) {
					// If multiple RMS are found, set them to state to display options to the user
					handleRmsMatch(rmsMatch)
					console.log(rmsMatch, 'multiple matches found')
					navigate('/choice')
				}
			} else {
				// Handle the case where no matching RMS is found
				setErrorMessage('We could not find the server associated with your email address. Please contact your Ava administrator or Ava Support at support@avarobotics.com.')
				console.log('No match found')
			}
		} catch {
			// Handle the case where an exception occurs (e.g., no match found)
			setErrorMessage('We could not find the server associated with your email address. Please contact your Ava administrator or Ava Support at support@avarobotics.com.')
			console.log('Error: no match found')
		}
		setUsername(username)
		console.log(username, 'this is the username')
	}

	return (
		<div className='outer-container' style={{ maxWidth: '800px', textAlign: '-webkit-center', paddingTop: '5%' }}>
			<div className='loginContent'>
				<div className='titlebar'>
					<Image src="newava.png" style={{ width: '150px' }} />
					<div className='page-container' style={{ marginTop: '5%', display: 'flex', gap: '10px' }}>
						<div style={{ width: '50%'}}>
							<Form.Label className='formtitle form-label app-name' >Login to Ava Telepresence App: </Form.Label>
							<Card className='bigButton'>
								<Card.Body className='card1'>
									<Form className='form' onSubmit={handleSubmit} ref={formRef} id="myForm">
										<Form.Group className="group" controlId="formBasicEmail">
											<Form.Label className='formtitle'>Please enter your email address</Form.Label>
											<Form.Control 
												className='input' 
												required
												type="text"
												placeholder="Enter email"
												value={username}
												onChange={handleUsernameChange}
											/>
											{errorMessage && (
												<Form.Label
													style={{
														color: '#808080',
														marginTop: '5px',
														textAlign: 'left',
														fontSize: 'smaller',
														fontWeight: 'bold'
													}}>
													<img src={warningIcon} alt="Warning Icon" style={{ width: '0.9em', marginRight: '4px' }} />
													{errorMessage}
												</Form.Label>
											)}
										</Form.Group>
									</Form>
								</Card.Body>
								<Button 
									className='submit1' 
									variant="secondary" 
									type="button" 
									onClick={() => formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))}
								>
									Continue
								</Button>
							</Card>
						</div>
						<div style={{ width: '48% !important'}}>
							<Form.Label className='formtitle form-label app-name' >Login to Ava Facilities Manager Hub: </Form.Label>
							<Button 
								className='submit1'
								type="button" 
								style={{maxWidth:'200px'}} 
								onClick={() => window.location.href = 'https://fa.ava8.net/'}
							>
								Click Here
							</Button>
						</div>
					</div>
				</div>
			</div>
			<div style={{ color: '#ddd' }}>
				<br></br>
				<p className='support' >Your organization has an Ava but you need an account, <a className='support link' href="https://www.avarobotics.com/customer-support" target="_blank">Contact Support</a></p>
				<br></br>
				<p className='support' >Your organization doesn't have an Ava but you want to try it, <a className='support link' href="https://www.avarobotics.com/request-a-demo" target="_blank">Request Demo</a></p>
			</div>
		</div>
	)
}
export default Login